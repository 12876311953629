.Draft,
.Limited,
.Sealed,
.Two {
  background-color: var(--ion-color-accent);
  color: var(--ion-color-light);
}

.Casual,
.Commander,
.Constructed,
.Other,
.Pauper {
  background-color: silver;
  color: var(--ion-color-dark);
}

.Standard {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
}

.Modern {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-light);
}

.Legacy {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
}

.Vintage {
  background-color: var(--ion-color-black);
  color: white;
}
