$color-names: primary secondary tertiary success warning action danger dark medium light;
$color-styles: shade tint contrast;

$menu-shadow: inset -27px 0px 17px -31px rgba(0, 0, 0, 0.75) !important;

:root.theme-night {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;


  &.plt-mobile:not(.plt-tablet):not(.plt-mobileweb) {
    .ng-progress-bar {
      top: calc(100vh - 1px - var(--ios-status-bar-height, 0px)) !important;
      transform: rotateX(180deg);
      z-index: 999999;
    }
  }

  .spinner {
    top: unset !important;
    bottom: 15px;
  }

  *:focus {
    outline: 0 !important;
  }

  --td-button-color-active: #A577CD;
  --td-button-color-hover: #6A5787;
  --td-button-color-hover-rgb: 106,
  87,
  135;
  --td-button-color-default: #544A63;
  --td-button-height: 36px;
  --td-tab-font-size: 13px;

  --td-overlay-background-rgb: 48,
  44,
  55;
  --td-overlay-background-opacity: .9;
  --td-overlay-background: rgba(var(--td-overlay-background-rgb), var(--td-overlay-background-opacity, .9));
  --td-overlay-blur-radius: 5px;

  --td-nav-header-height: 106px;

  --td-section-active-background: rgba(70, 57, 86, 0.8);

  --td-toolbar-height: 56px;

  --ion-item-background: rgba(0, 0, 0, 0);
  --ion-item-border-color: rgba(var(--ion-color-primary-rgb), 0.3);

  --ion-text-color: #DED8E6;
  --ion-text-color-rgb: 222,
  216,
  230;

  --ion-toolbar-background: #111;
  --ion-toolbar-border-color: rgba(var(--ion-color-primary-rgb), 0.3);

  // --ion-backdrop-opacity: 0.6;
  --ion-background-color: #323135;
  --ion-background-color-rgb: 50,
  49,
  53;
  --ion-background-color-contrast: #ffffff;
  --ion-background-color-contrast-rgb: 255,
  255,
  255;
  --ion-background-color-shade: #2c2b2f;
  --ion-background-color-tint: #474649;

  --ion-overlay-background-color: var(--ion-background-color);

  --ion-color-step-50: #2d2e32;
  --ion-color-step-100: #37393d;
  --ion-color-step-150: #424347;
  --ion-color-step-200: #4c4e52;
  --ion-color-step-250: #57585c;
  --ion-color-step-300: #616366;
  --ion-color-step-350: #6c6d71;
  --ion-color-step-400: #76787b;
  --ion-color-step-450: #818286;
  --ion-color-step-500: #8b8d90;
  --ion-color-step-550: #96979a;
  --ion-color-step-600: #a0a1a5;
  --ion-color-step-650: #abacaf;
  --ion-color-step-700: #b5b6ba;
  --ion-color-step-750: #c0c1c4;
  --ion-color-step-800: #cacbce;
  --ion-color-step-850: #d5d6d9;
  --ion-color-step-900: #dfe0e3;
  --ion-color-step-950: #e9ebee;


  color: var(--ion-text-color);

  code.markdown {
    border-radius: 2px;
    font-weight: 500;
    letter-spacing: 0px;
    margin: 0 1px 0 1px;
    padding: 0 3px;
    background-color: rgba(var(--ion-color-dark-rgb), 0.5);
    color: var(--ion-color-tertiary);
    white-space: nowrap;
  }


  span.monospace {
    display: inline-flex;

    span.monospace__item {
      flex-basis: 100%;
      text-align: center;
      flex-basis: 100%;
      width: 0.55em;
    }
  }


  html {
    display: block !important;
  }

  h1 {
    color: #FFFFFF;
  }


  ion-router-outlet {
    ion-content[slot="master"] {
      // --background: linear-gradient(to bottom, #3e344b 6%, #1f1f1f 100%);
    }
  }

  td-responsive-router-outlet {
    ion-content {
      --background: rgba(0, 0, 0, 0);
    }
  }

  td-master-detail {
    // background: rgb(62, 52, 75);
    // background: rgb(31, 31, 31);
    // background: linear-gradient(145deg, #3e344b 150px, #1f1f1f 1000px);
    background: linear-gradient(165deg, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 800px);

    &.platform--mobile {
      @media (orientation: landscape) {
        background: linear-gradient(165deg, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 500px);
      }
    }

    &.platform--desktop {
      background: radial-gradient(circle at 40% top, rgba(62, 52, 75, 1) 0%, rgba(31, 31, 31, 1) 100%);

      @media screen and (max-width: 1200px) {
        background: radial-gradient(circle at 350px top, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 1000px);
      }

      @media screen and (max-width: 1200px) {
        background: radial-gradient(circle at 350px top, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 1000px);
      }

      @media screen and (max-width: 900px) {
        background: radial-gradient(circle at 300px top, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 1000px);
      }

      @media screen and (max-width: 600px) {
        background: radial-gradient(circle at 250px top, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 800px);
      }

      @media screen and (max-width: 420px) {
        background: radial-gradient(circle at 150px top, rgba(62, 52, 75, 1) 0px, rgba(31, 31, 31, 1) 600px);
      }
    }

    // &::after {
    //   content: "";
    //   background-image: $logo-img;
    //   background-position: 90% 0%;
    //   background-repeat: no-repeat;
    //   background-size: 75%;
    //   filter: blur(5px);

    //   opacity: 0.05;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   position: absolute;
    //   z-index: -1;   
    // }
  }

  a {
    text-decoration: none;
  }

  app-main {
    will-change: transform;
    transform: translateZ(0);
  }

  ion-action-sheet {
    --color: var(--ion-color-light);

    &.ios {
      --button-color: var(--ion-color-light-shade);

      button {
        margin-top: 1px
      }

      &.action-sheet-translucent {
        .action-sheet-group {
          &:first-child {
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
          }

          &:last-child {
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
          }
        }

      }
    }

    &.md {
      .action-sheet-title {
        height: unset;
      }

      .action-sheet-icon {
        padding-bottom: none;
      }

      .action-sheet-button {
        font-size: 17px;

        &.action-sheet-destructive {
          --button-color: var(--ion-color-danger-tint);

          ion-icon {
            --color: var(--ion-color-danger-tint);
          }
        }
      }
    }

    @each $color in $color-names {
      .#{$color} {
        --button-color: var(--ion-color-#{$color});

        ion-icon {
          --color: var(--ion-color-#{$color});
        }
      }
    }
  }

  ion-button.tiny {
    height: 20px;
    min-height: 20px;
    font-size: 12px;
    line-height: 12px;
    --padding-start: 4px;
    --padding-end: 4px;
  }

  ion-button:not(.button-clear) {
    --border-radius: 4px;

    &:not(.button-outline) {
      --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }

  ion-content {
    --background: rgba(0, 0, 0, 0);
  }

  ion-toast.top {
    @media only screen and (max-width: 767.1px) {
      margin-top: 56px;
      height: calc(100% - 56px);
    }
  }

  hr {
    --line-length: var(--hr-length, 80%);
    --line-thickness: var(--hr-thickness, 1px);
    --line-margin: var(--hr-margin, 15px);
    --line-direction: to var(--hr-direction, right);
    --line-opacity: var(--hr-opacity, 0.2);
    --line-color: var(--hr-color, rgba(var(--ion-color-primary-rgb), var(--line-opacity)));
    border: 0;
    height: var(--line-thickness);
    background-image: linear-gradient(var(--line-direction), rgba(0, 0, 0, 0), var(--line-color), rgba(0, 0, 0, 0));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--line-margin);
    margin-top: var(--line-margin);
    width: var(--line-length);

    &.bold {
      --hr-opacity: 0.5;
    }

    &.collapsed {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.full-length {
      --hr-length: 100%;
    }

    &.vertical {
      --hr-direction: bottom;
      margin-left: var(--line-margin);
      margin-right: var(--line-margin);
      height: calc(var(--line-length) - var(--line-margin) * 2);
      width: var(--line-thickness);

      &.collapsed {
        --hr-margin: 0;
      }
    }

    &.left,
    &.right,
    &.top,
    &.bottom {
      background-image: linear-gradient(var(--line-direction), rgba(0, 0, 0, 0), var(--line-color));
    }

    &.left {
      --hr-direction: left;
      margin-left: 0;
    }

    &.right {
      --hr-direction: right;
      margin-right: 0;
    }

    &.top {
      --hr-direction: top;
      margin-top: 0;
    }

    &.bottom {
      --hr-direction: bottom;
      margin-bottom: 0;
    }

    @each $color in $color-names {
      &.#{$color} {
        --hr-color: rgba(var(--ion-color-#{$color}-rgb), var(--line-opacity, 0.2));
      }

      @each $style in $color-styles {
        &.#{$color}-#{$style} {
          --hr-color: rgba(var(--ion-color-#{$color}-#{$style}-rgb), var(--line-opacity, 0.2));
        }
      }
    }
  }

  ion-content {
    ion-toolbar {
      --background: none;
      --min-height: var(--td-toolbar-height);

      /** Second toolbar height to match td-nav-header height in detail outlets */
      +ion-toolbar {
        --min-height: calc(var(--td-nav-header-height, 100px) - var(--td-toolbar-height, 50px));
      }
    }
  }

  ion-header {
    box-shadow: none;

    &::after {
      display: none;
    }

    ion-toolbar {
      --background: var(--td-overlay-background);
      --min-height: var(--td-toolbar-height);

      &:first-of-type:first-of-type {
        padding-top: calc(var(--ion-safe-area-top, 0px) - 15px) !important;
      }

      &:last-of-type:last-of-type {
        padding-bottom: 0 !important;
        --border-width: 0 0 0 0;
      }

    }
  }

  ion-footer {
    box-shadow: none;

    &::before {
      display: none;
    }

    ion-toolbar {
      --border-width: 0 0 0;
      --background: var(--td-overlay-background);
      --min-height: var(--td-toolbar-height);
      --padding-top: 0;
      --padding-bottom: 0;

      &:first-of-type:first-of-type {
        padding-top: 0 !important;
      }

      &:first-child:first-child {
        --border-width: 0 0 0;
      }

      >* {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .item-sliding-active-slide,
  .item-sliding-active-options-end {
    ion-item {
      --background: var(--ion-background-color);
    }
  }

  img,
  ion-img {

    &:not([src]),
    &[src=""] {
      visibility: hidden;
    }
  }

  ion-img {
    /** 
     * Bug fix for:
     * https://github.com/ionic-team/ionic/issues/18734
     */
    min-height: 1px !important;
  }

  ion-alert {

    .alert-head,
    .alert-input-group,
    .alert-message {
      background: var(--td-overlay-background);

      .alert-sub-title {
        font-size: 12px;
      }
    }

    .alert-input-group {
      &.sc-ion-alert-ios {
        padding-top: 12px;
      }
    }

    .alert-head {
      padding-bottom: 15px !important;

      &.sc-ion-alert-md {
        padding-bottom: 20px !important;
      }
    }

    .alert-title {
      margin: 0;
    }

    .alert-message {
      border-top: 1px solid rgba(var(--ion-color-primary-rgb), 0.28);
      padding-top: 20px !important;

      &:empty {
        border-top: 0 !important;
        padding: 0px !important;
      }

      background: var(--ion-color-dark);
    }

    .alert-button-group {
      border-top: 1px solid rgba(var(--ion-color-primary-rgb), 0.2);
      background: var(--td-overlay-background);

      .alert-button.sc-ion-alert-ios {
        border-top: 0 !important;
        border-color: rgba(var(--ion-color-primary-rgb), 0.2) !important;
      }
    }

    .alert-checkbox-group,
    .alert-radio-group {
      border: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    .alert-radio-group.sc-ion-alert-md,
    .alert-checkbox-group.sc-ion-alert-md {
      max-height: 265px;
    }

    &.upgrade {

      .alert-button {
        color: var(--ion-color-medium) !important;
      }

      .alert-button-role-default {
        color: var(--ion-color-secondary) !important;
        font-weight: bold;
      }

      .alert-button-group {
        justify-content: center;
      }

      &.md {
        .alert-button {
          margin-inline-end: 0;
        }

        .alert-button-inner {
          justify-content: center;
          align-items: center;
        }

        .alert-button-group {
          justify-content: center;
          justify-content: space-between;
        }

        .alert-button-role-default {
          border: solid 2px;
          border-radius: 5px;
          height: 35px;
        }

        .alert-wrapper {
          border-radius: 15px;
        }
      }
    }
  }

  ion-menu {

    &::part(container) {
      will-change: transform;
      z-index: 10;
      // Safari fixes
      transform: translate3d(0, 0, 0px);
      transform-style: preserve-3d;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0px);
      -webkit-transform-style: preserve-3d;
    }

    &.global-tools {
      will-change: transform;
      // Safari fixes
      transform: translate3d(0, 0, 0px);
      transform-style: preserve-3d;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0px);
      -webkit-transform-style: preserve-3d;

      --background: rgba(0, 0, 0, 0);
      --border: 0;
      --global-tools-max-width: 100%;
      --min-width: var(--global-tools-max-width);
      --max-width: var(--global-tools-max-width);
      --width: var(--global-tools-max-width);
      box-shadow: -10px 20px 10px 10px rgba(0, 0, 0, 0.55);

      .global-tools--inner {
        height: 100%;
        width: 100%;
        background: rgba(var(--ion-color-dark-rgb), 0.95);
      }

      &::part(backdrop) {
        display: none;
        pointer-events: none !important;
      }
    }
  }

  ion-modal {

    .ion-page {
      position: absolute;
    }

    ion-title.ios:not(:last-child) {
      position: relative;
      text-align: center;
      padding: 0;
      padding-left: 10px;
    }

    /* Default modal styling */
    .modal-wrapper {
      --background: linear-gradient(to bottom, #3e344b 0px, #1f1f1f 800px);

      @media only screen and (min-height: 650px) and (min-width: 768px) {
        --min-height: 90vh;
      }
    }

    ion-toolbar {
      padding-left: 0;
      padding-right: 0;
    }

    ion-header {
      ion-toolbar {
        min-height: var(--td-toolbar-height);
        --min-height: var(--td-toolbar-height);
        --background: var(--td-overlay-background);
      }
    }


    /* Modal custom styles */
    &.modal-full-height {
      --min-height: 100%;
      --border-radius: 0;
    }

    &.modal-fullscreen {
      --min-height: 100%;
      --min-width: 100%;
      --border-radius: 0;
    }

    &.modal-700 {
      --backdrop-opacity: 0.8 !important;

      @media only screen and (min-height: 650px) and (min-width: 768px) {
        --min-height: 700px;
      }

      @media only screen and (max-height: 649.9px) {
        --min-height: 95%;
      }
    }

    &.modal-tall {
      --backdrop-opacity: 0.8 !important;

      @media only screen and (min-height: 650px) and (min-width: 768px) {
        --min-height: 80%;
      }

      @media only screen and (max-height: 649.9px) {
        --min-height: 95%;
      }
    }

    &.modal-thin {
      @media only screen and (min-width: 768px) {
        --max-width: 400px;
      }
    }

    &.modal-scaled {
      --min-height: 100%;
      --border-radius: 0;

      @media only screen and (min-height: 650px) and (min-width: 768px) {
        --min-height: 90%;

        &.ios {
          --border-radius: 10px;
        }
      }
    }

    &.modal-short {
      --max-height: 70vh;
    }

    &.modal-large {
      --min-height: 90%;
      --min-width: 90%;
    }

    &.modal-transparent {
      --background: rgba(0, 0, 0, 0) !important;
      --backdrop-opacity: 0.8 !important;
    }
  }

  ion-popover {
    --backdrop-opacity: 0.4 !important;

    &.select-popover {
      &.md {
        --max-height: 360px;
        margin-top: 4px;
        margin-left: 2px;
      }

      &.ios {
        --max-height: 355px;
      }

      .popover-content {

        ion-item {
          --padding-start: 16px;
        }

        ion-list-header {
          --background: var(--td-overlay-background);

          &.ios {
            padding-bottom: 25px;
          }
        }

        ion-list-header+ion-item {
          --background: var(--td-overlay-background);

          h3 {
            font-size: 12px;
          }

          ion-label {
            padding-bottom: 10px;
          }
        }
      }

      .popover-viewport {
        overflow-y: scroll;
      }
    }
  }

  .ion-page {
    contain: strict;
  }

  ion-segment {
    ion-label {
      font-size: var(--td-tab-font-size);
      letter-spacing: normal;
    }

    ion-segment-button {
      min-width: auto !important;
      --padding-start: 5px;
      --padding-end: 5px;
    }
  }

  ion-item {
    ion-select {
      // margin fix - prevents select from moving when clicked
      margin-bottom: 2px;
      margin-top: 2px;
      // end margin fix

      &[slot=end] {
        margin-left: 10px;
      }

      &[slot=start] {
        margin-right: 10px;
      }
    }
  }

  ion-select {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    width: fit-content;
    $height: 36px;
    $height-offset: 2px;
    flex-shrink: 0;
    height: $height;
    min-height: $height;
    --padding-top: #{1 + $height-offset};
    --padding-bottom: #{4 + $height-offset};

    --select-color: var(--td-button-color-default);

    $color-names: primary secondary tertiary success warning action danger dark medium light button transparent;
    @each $color in $color-names {
      &[color=primary] {
        --select-color: var(--ion-color-primary);
      }
    }

    border: solid 2px;
    border-color: var(--select-color);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 11px;
    text-transform: uppercase;
    line-height: 17px;

    &[aria-label] {
      --padding-top: #{1 + $height-offset};
      --padding-bottom: #{2 + $height-offset};
    }

    &[slot=end],
    &[slot=start] {
      max-width: 100px;
    }

    // background: var(--select-color);
    background: none;

    ::shadow {
      .select-text {
        text-transform: uppercase;
      }
    }

    &::part(icon) {
      transform: none;
    }

    &::part(icon) {
      color: var(--ion-color-light);
      opacity: 1;
      margin-top: 0.5px;
      margin-right: -2px;
    }

    &.icon-only {
      width: 40px;
      justify-content: center;

      &::part(placeholder) {
        display: none;
      }

      &::part(text) {
        display: none;
      }

      &::part(icon) {
        color: var(--ion-color-light);
        opacity: 1;
        margin-left: 2px;
        margin-right: 3px;
        width: 17px;
        height: 17px;
      }

      &[aria-label] {
        &::part(icon) {
          padding-top: 2px;
        }
      }
    }
  }

  ion-split-pane {
    padding-left: var(--ion-safe-area-left);
    padding-right: var(--ion-safe-area-right);
    background: var(--menu-background, rgba(0, 0, 0, 0.5));

    --menu-background: #1f1f20;
    --menu-expanded-max-width: 210px;
    --menu-opened-max-width: 240px;
    --menu-width: 0px;

    &:not(.split-pane-md),
    &.split-pane-visible {
      .split-pane-main {
        // box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.55) !important;
      }

      .side-menu__outer {
        box-shadow: $menu-shadow;
      }
    }

    ion-menu.side-menu {
      --background: var(--menu-background, rgba(0, 0, 0, 0.5));
      --border: 0;

      .side-menu__inner {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        text-transform: uppercase;

        ion-icon {
          background: #3e3047;
          border-radius: 50%;
          padding: 6px;
          color: var(--ion-color-light-shade);
          font-size: 21px;
          margin: 7px 16px 7px 0;
        }

        ion-button {
          height: 47px;
          font-size: 16px;
          width: 100%;
          border-right: solid 2px rgba(0, 0, 0, 0);
          color: var(--td-button-color-active);

          .nav-menu-item__inner {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-items: left;

            ion-icon {
              flex-basis: 1;
              flex-shrink: 0;
            }
          }

          &.active {
            --color: var(--td-button-color-active);
            background: rgba(0, 0, 0, 0) linear-gradient(to right, rgba(165, 119, 205, 0), rgba(165, 119, 205, 0.1));
            border-right: solid 2px var(--td-button-color-active);

            ion-label {
              color: var(--td-button-color-active);
            }

            ion-icon {
              background: var(--td-button-color-active);
            }

            &.outlet--detail {
              background: rgba(0, 0, 0, 0) linear-gradient(to right, rgba(98, 102, 253, 0), rgba(98, 102, 253, 0.1));
              border-right: solid 2px var(--ion-color-secondary) !important;

              ion-icon {
                background: var(--ion-color-secondary);
                stroke: var(--ion-color-light) !important;
              }

              ion-label {
                color: var(--ion-color-secondary);
              }
            }
          }
        }

        ion-list {
          margin-bottom: 0;
        }

        .side-menu__logo {
          height: 75px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: linear-gradient(var(--td-overlay-background), var(--menu-background, rgba(0, 0, 0, 0.5)));

          &.ios {
            box-shadow: $menu-shadow;
          }
        }

        .side-menu__contextual {

          ion-list {
            margin-bottom: 1px;
          }

          &--subnav {
            ion-button {
              --background: rgba(0, 0, 0, 0) linear-gradient(to right, rgba(165, 119, 205, 0), rgba(165, 119, 205, 0.1));
              border-right: solid 2px var(--td-button-color-default);

              &.active {
                border-right: solid 2px var(--td-button-color-active);
              }
            }
          }
        }

        .side-menu__primary {
          flex: 1 0 auto !important;
        }
      }

      .side-menu__header,
      .side-menu__footer {
        font-size: 12px;
        text-transform: uppercase;
        background: var(--td-overlay-background);
        margin: 0px;
        padding: 0px;

        &.ios {
          box-shadow: $menu-shadow;
        }

        ion-button {
          ion-label {
            width: 100%;
            text-align: center;
          }
        }

        ion-list {
          margin: 0px;
          padding: 0px;
        }

      }

      .side-menu__footer {
        background: linear-gradient(var(--menu-background, rgba(0, 0, 0, 0.5)), var(--td-overlay-background));
      }
    }

    &.split-pane--condensed {
      --menu-width: 110px;

      ion-menu {
        --menu-expanded-max-width: 110px;

        .side-menu__inner {

          ion-button {
            font-size: 12px;
            width: 100%;
            max-width: 100%;

            .nav-menu-item__inner {
              flex-direction: column;
              align-items: center;

              ion-icon {
                margin: 0;
                margin-bottom: 5px;
              }
            }
          }

          ion-button {
            &.has-icon {
              $height: 64px;
              max-height: $height;
              height: $height;
            }
          }

        }


        .side-menu__header,
        .side-menu__footer {
          box-shadow: $menu-shadow;

          ion-button {
            --height: 28px;
            max-height: 28px;
            height: 28px;
          }
        }

        .side-menu__logo {
          padding-top: 10px;
          padding-bottom: 5px;
          height: 70px;
          margin: 0;
          box-shadow: $menu-shadow;
        }
      }
    }

    .split-pane-side {
      --width: var(--menu-opened-max-width);
    }

    &.split-pane-visible {

      .split-pane-side {
        min-width: var(--menu-expanded-max-width);
        max-width: var(--menu-expanded-max-width);
        width: var(--menu-expanded-max-width);
      }
    }


    &.split-pane-visible {
      @media only screen and (max-width: 767px) {
        .split-pane-side {
          display: none;
        }
      }
    }

    &:not(.split-pane-visible) {
      @media only screen and (min-width: 768px) {
        .split-pane-side {
          display: none;
        }
      }
    }
  }

  ion-title {
    --color: var(--ion-text-color);
    margin-left: 0;

    &.md {
      padding-left: 10px;
    }

    &.subtitle {
      font-size: 14px;
    }
  }

  ion-toggle {
    --background: var(--ion-color-step-250);
    --handle-background: var(--ion-color-step-450);
  }

  ion-toolbar {
    display: flex;

    &.toolbar-searchbar {
      --padding-start: 0;
      --padding-end: 0;
    }

    ion-buttons[slot=start] {
      ion-button {
        text-transform: uppercase;
        font-size: 12px;

        &:first-child:not(.button-has-icon-only) {
          margin-left: 8px;

          &[fill='solid'] {
            margin-right: 10px;
          }
        }
      }
    }

    ion-buttons[slot=end] {
      ion-button {
        text-transform: uppercase;
        font-size: 12px;

        &:last-child:not(.button-has-icon-only) {
          margin-right: 8px;

          &[fill='solid'] {
            margin-left: 10px;
          }
        }
      }
    }

    ion-segment:not(:only-child):not(.md) {
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;

      @media only screen and (max-width: 320px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    ion-segment:only-child:not(.md) {
      margin-left: 10px;
      margin-right: 10px;
      min-width: calc(100% - 20px);
    }

    // TODO: COMPONENTIZE THIS SEARCH:43251
    .tools__button {
      --padding-start: 10px;
      --padding-end: 10px;

      @media only screen and (max-width: 320px) {
        --padding-start: 8px;
        --padding-end: 8px;

        ion-label {
          font-size: 12px !important;
        }
      }

      position: relative;
      height: 100%;

      &.active {
        &::before {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          border-bottom: solid 2px;
        }
      }
    }
  }

  ng-component {
    router-outlet {
      display: none;
    }

    &>* {
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      contain: layout size style;
      overflow: hidden;
    }
  }

  virtual-scroller {
    width: 100%;

    .scrollable-content {
      height: unset !important;
      max-height: unset !important;
    }
  }

  table {
    text-align: left;

    th {
      font-size: 14px;
      text-transform: uppercase;
    }

    td,
    th {
      padding: 5px;
    }

    td {
      font-size: 14px;
    }

    tr {
      padding-bottom: 10px;

      &:last-of-type {
        padding-bottom: 0px;
      }
    }

    th,
    tr {
      &.even {
        background-color: rgba(#FFF, .02);
      }

      &.cursor-pointer {
        &:hover {
          background: rgba(var(--td-button-color-hover-rgb), 0.2) !important;
        }
      }
    }
  }

  --scrollbar-height: 0px;
  --scrollbar-width: 0px;
  --scrollbar-width-inactive: var(--scrollbar-width);
  --scrollbar-track-color: initial;
  --scrollbar-track-color-inactive: var(--scrollbar-track-color);
  --scrollbar-track-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  --scrollbar-track-box-shadow-inactive: var(--scrollbar-track-box-shadow);
  --scrollbar-thumb-color: rgba(var(--ion-color-primary-rgb), 0.6);
  --scrollbar-thumb-color-inactive: rgba(var(--ion-color-primary-rgb), 0.3);
  // --scrollbar-thumb-background-image: linear-gradient(45deg,
  //   rgba(255, 255, 255, .2) 25%,
  //   transparent 25%,
  //   transparent 50%,
  //   rgba(255, 255, 255, .2) 50%,
  //   rgba(255, 255, 255, .2) 75%,
  //   transparent 75%,
  //   transparent);

  * {
    scrollbar-color: rgba(145, 95, 194, 0.3) rgba(0, 0, 0, 0);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-height);
    }

    &::-webkit-scrollbar-track {
      // Nothing here
    }

    &::-webkit-scrollbar-thumb {
      width: var(--scrollbar-width);
      height: var(--scrollbar-height);
      background-color: var(--scrollbar-thumb-color);
      background-image: var(--scrollbar-thumb-background-image);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: var(--scrollbar-thumb-color);
    }
  }

  *[ng-reflect-router-link] {
    cursor: pointer;
  }

  *[ng-reflect-router-link]:focus,
  *[ng-reflect-router-link]:active {
    outline: none;
    -moz-outline-style: none;
    outline-style: none;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
  }
}
