:root {

  // BROWSER GLOBAL STYLES
  input:autofill,
  input:autofill:hover,
  input:autofill:focus,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:autofill,
  textarea:autofill:hover,
  textarea:autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:autofill,
  select:autofill:hover,
  select:autofill:focus
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-size: 13px;
    font-family: var(--ion-font-family);
    transition-delay: 99999s;
    background-color: rgba(0, 0, 0, 0) !important;
    background-image: none !important;
    color: var(--ion-color-light) !important;
    -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
    -webkit-text-fill-color: var(--ion-color-light) !important;
  }

  --td-input-background: #1F1E23;
  --td-input-border-size: 2px;
  --td-input-border: solid var(--td-input-border-size) rgba(var(--ion-color-secondary-rgb), 0.2);
  --td-input-border-radius: 3px;
  --td-input-border-focused: solid var(--td-input-border-size) rgba(var(--ion-color-secondary-rgb), 0.5);
  --td-input-border-danger: solid var(--td-input-border-size) rgba(var(--ion-color-danger-rgb), 0.4);
  --td-input-border-danger-focused: solid var(--td-input-border-size) rgba(var(--ion-color-danger-rgb), 0.6);
  --td-input-height: 40px;
  --td-input-font-size: 15px;
  --td-input-margin: 5px;

  ion-item-divider[lines=none] {
    border-bottom: 0;
  }

  ion-item-divider,
  ion-item {

    z-index: 2;
    --background: rgba(0, 0, 0, 0);

    &:not(.ion-no-padding) {
      --padding-start: 10px;
      --inner-padding-start: 0px;
      --padding-end: 10px;
      --inner-padding-end: 0px;
    }

    // ion-icon {
    //   font-size: 20px;
    // }

    ion-label,
    ion-label.ion-text-wrap {
      font-size: var(--td-input-font-size);

      &.label-stacked {
        font-size: var(--td-input-font-size);
        margin-bottom: 10px;

        +ion-input {
          margin-bottom: var(--td-input-margin);
        }
      }
    }

    &.item-textarea {
      --min-height: 66px;
    }

    .sublabel {
      display: block;
      text-transform: none;
      font-size: 12px !important;
      line-height: 14px;
    }

    .subtext {
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
      font-size: 13px !important;
      opacity: 0.8;
    }
  }

  ion-item-divider {
    --padding-end: 0px;
    --inner-padding-end: 0;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 15px;
  }

  ion-item {
    font-size: 16px;
    --ion-item-background-activated: rgba(0, 0, 0, 0) linear-gradient(to left, rgba(165, 119, 205, 0), rgba(165, 119, 205, 0.2));
    --ion-item-background-focused: none; // var(--ion-item-background-activated);

    &.lines {
      //&:not(.item-lines-none) {
      --inner-border-width: 0;
      margin-bottom: -1px;

      &::after {
        content: "";
        position: absolute;
        bottom: 1px;
        right: 0px;
        display: block;
        height: 1px;
        width: 70%;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--ion-color-primary-shade));
        opacity: 0.6;
        margin-bottom: -1px;
      }
    }

    &.nested {
      border-left: solid 2px var(--ion-color-primary);
      margin-left: 10px;
    }

    &.active,
    &.activated {
      background: var(--ion-item-background-activated);
    }

    &.ion-no-padding {
      --inner-padding-start: 0;
      --inner-padding-end: 0;
    }

    ion-button[slot='end'] {
      height: 80%;
      margin-left: 0;
      margin-right: 0;

      ion-icon[slot='icon-only'] {
        padding: 10px;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &.item-textarea {
      ion-label.label-stacked {
        margin-bottom: 1px;
      }
    }

  }

  ion-list {
    padding-top: 0;
  }

  ion-list-header:not(.sc-ion-select-popover-ios):not(.sc-ion-select-popover-md),
  .list-header {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    justify-content: start;
    line-height: 12px;
  }

  ion-item-divider,
  ion-list-header,
  td-card-group-header {
    ion-badge {
      border: solid 2px var(--ion-color-tertiary);
      // border-radius: 15px;
      min-width: 30px;
      filter: drop-shadow(0 0 2px var(--ion-color-dark));
    }

    ion-button[slot=end][fill=clear] {
      margin-right: 0;
    }
  }

  // Spacing & Tweaks
  input:not(.searchbar-input),
  textarea,textarea.native-textarea {
    font-size: 14px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    min-height: 40px;

    &::placeholder {
      font-style: italic;
      opacity: .5;
    }
  }

  .alert-input,
  ion-input,
  ion-input:not(.legacy-input) {
    // Set the default input element height
    min-height: var(--td-input-height);

    // Set default margins for stacked inputs in a list
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .alert-input,
  ion-input:not(.border-0),
  ion-textarea {
    border: var(--td-input-border);

    &.has-focus {
      border: var(--td-input-border-focused);
    }
  }

  .alert-input,
  ion-input,
  ion-textarea {
    --background: var(--td-input-background);
    border-radius: var(--td-input-border-radius);
    font-size: var(--td-input-font-size);
    line-height: var(--td-input-font-size);

    .input-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }

    textarea {
      padding-inline-start: 10px !important;
      padding-inline-end: 10px !important;
    }
  }

  ion-textarea {
    min-height: unset;
  }

  .alert-input {
    background: var(--td-input-background);

    &.sc-ion-alert-md {
      margin-bottom: 5px !important;
    }
  }

  // Form Validation States
  ion-item {

    ion-label {
      p {
        color: rgba(var(--ion-color-light-rgb), 0.7) !important;
      }
    }

    &.item-input {
      ion-button[slot=end] {
        height: 41px;
      }

      ion-input,
      ion-textarea {
        border: var(--td-input-border);
      }

      >ion-label {
        color: var(--ion-color-light);
      }

      &.item-has-focus {

        ion-input,
        ion-textarea {
          border: var(--td-input-border-focused);
        }

        >ion-label {
          color: var(--ion-color-secondary);
        }
      }

    }

    &.item-input.item-invalid,
    &.item-input.ion-invalid:not(.ion-pristine) {

      ion-input,
      ion-textarea {
        border: var(--td-input-border-danger);
        // --background: rgba(var(--ion-color-danger-rgb), 0.05);
      }

      >ion-label {
        color: var(--ion-color-danger);
      }

      &.item-has-focus {

        ion-input,
        ion-textarea {
          border: var(--td-input-border-danger-focused);
        }
      }
    }
  }
}
