// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'swiper/scss';

@import './theme/forms.scss';
@import './theme/keyboard.scss';
@import './theme/tour.scss';
@import './theme/simulator-drag-drop.scss';
@import './theme/transitions.scss';
@import './theme/utility.scss';
@import './theme/mtg.scss';

@import '../node_modules/overlayscrollbars/styles/overlayscrollbars.min.css';

// @import './theme/fonts-open-sans.scss';
// @import './theme/fonts-roboto.scss';
// :root {
//   --ion-font-family: "Open Sans";
//   font-family: "Open Sans";
//   font-weight: 400;
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font-family: "Roboto";
//     font-weight: 300;
//   }
// }

@import './theme/fonts-barlow.scss';

html,
body {
  overflow: hidden !important;
  max-height: 99.99%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  touch-action: none;
}

[hidden] {
  display: none !important;
}

// @import url('https://fonts.googleapis.com/css?family=Barlow:400,400i,700,700i&display=swap');
:root {
  --ion-font-family: "Barlow";
  font-family: "Barlow";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgba(0, 0, 0, 0.5);

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
  }

  h6 {
    font-size: 13px;
    font-weight: 700;
  }
}
