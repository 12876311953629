:root {
  td-portal-outlet.attached * {
    animation: 500ms fade-in;

    .custom-animation,
    .faded,
    .faded-1,
    .faded-2,
    .faded-3,
    .faded-4,
    .faded-5,
    .faded-6,
    .faded-7,
    .faded-8,
    .faded-9,
    .faded-10,
    .item-interactive-disabled,
    .item-interactive-disabled *,
    [ng-reflect-disabled="true"],
    td-nav-filters,
    td-nav-filters *,
    td-sticky-header,
    td-sticky-header *,
    [disabled] {
      animation: none;
    }
  }

  .animate-fade-in {
    animation: fade-in 1s forwards 1 ease-in-out !important;
  }

  .animate-fade-out {
    animation: fade-out 1s forwards 1 ease-in-out !important;
  }

  .animate-pulse-in {
    animation: pulse-out 1s forwards 1 !important;
  }

  .animate-pulse-out {
    animation: pulse-out 4s forwards infinite !important;
  }

  .animate-spin-left {
    animation: spin 30s infinite linear reverse !important;
  }

  .animate-spin-right {
    animation: spin 30s infinite linear !important;
  }

  .animate-stop, .animate-stop * {
    animation-play-state: paused !important;
  }

  .animate-vibrate {
    animation: vibrate-1 500ms forwards infinite ease-in-out !important;
  }

  // CONTROL CLASSES
  @for $i from 0 through 20 {
    .animate-times-#{$i} {
      animation-iteration-count: #{$i} !important;
    }

    .animate-delay-#{$i}00 {
      animation-delay: #{$i * 100}ms !important;
    }

    .animate-duration-#{$i}00 {
      animation-duration: #{$i * 100}ms !important;
    }
  }

  .animate-disable {
    animation: none !important;
  }

}



@keyframes fade-in {
  0% {
    opacity: var(--fade-min-opacity, 0);
  }

  100% {
    opacity: var(--fade-max-opacity, 1);
  }
}

@keyframes fade-in-partial {
  0% {
    opacity: var(--fade-min-opacity, 0);
  }

  100% {
    opacity: var(--fade-max-opacity, 0.5);
  }
}


@keyframes fade-out {
  0% {
    opacity: var(--fade-max-opacity, 1);
  }

  100% {
    opacity: var(--fade-min-opacity, 0);
  }
}


@keyframes fade-out-mostly {
  0% {
    opacity: var(--fade-max-opacity, 1);
  }

  100% {
    opacity: var(--fade-min-opacity, 0.3);
  }
}

// @keyframes slidein-bottom {
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }

//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// @keyframes slideout-bottom {
//   0% {
//     transform: translateY(0);
//     opacity: 1;
//   }

//   100% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
// }

$pulse-in-min: 0.0;
$pulse-in-max: 0.8;

@keyframes pulse-in {
  0% {
    opacity: var(--pulse-min-opacity, $pulse-in-min);
  }

  60% {
    opacity: var(--pulse-max-opacity, $pulse-in-max);
  }

  90% {
    opacity: var(--pulse-min-opacity, $pulse-in-min);
  }

  100% {
    opacity: var(--pulse-min-opacity, $pulse-in-min);
  }
}

$pulse-out-min: 0.0;
$pulse-out-max: 0.8;

@keyframes pulse-out {
  0% {
    opacity: var(--pulse-max-opacity, $pulse-out-max);
  }

  10% {
    opacity: var(--pulse-max-opacity, $pulse-out-max);
  }

  50% {
    opacity: var(--pulse-min-opacity, $pulse-out-min);
  }

  100% {
    opacity: var(--pulse-max-opacity, $pulse-out-max);
  }
}


@keyframes vibrate-1 {
  0% {
    transform: translate(0) rotateX(10deg);
  }

  20% {
    transform: translate(-2px, 2px) rotateZ(1deg) rotateX(10deg);
  }

  40% {
    transform: translate(-2px, -2px) rotateX(10deg);
  }

  60% {
    transform: translate(2px, 2px) rotateZ(-1deg) rotateX(10deg);
  }

  80% {
    transform: translate(2px, -2px) rotateX(10deg);
  }

  100% {
    transform: translate(0) rotateX(10deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


$translateY: 0px;

@keyframes pop {
  0% {
    will-change: transform;
    transform: none;
  }

  10% {
    transform: translateZ(0.0px);
  }

  35% {
    transform: translateY($translateY) scale(1.035) rotateZ(var(--card-block-tilt, 0));
  }

  45% {
    transform: translateY($translateY - 1) scale(1.05) rotateZ(var(--card-block-tilt, 0));
  }

  100% {
    transform: none;
  }
}
