:root.theme-electron {

  // Window Drag-handle styles
  ion-content,
  ion-header,
  ion-menu {
    .no-scroll {
      --overflow: hidden;
    }

    ion-toolbar {
      -webkit-app-region: drag;

      ion-button {
        -webkit-app-region: no-drag;
      }

      ion-searchbar {
        -webkit-app-region: no-drag;
      }
    }
  }
}
