html.plt-capacitor {
  #intercom-container {
    background: white;
    height: calc(100%);

    iframe {
      padding-top: 20px;
      height: calc(100% - 20px - var(--ion-safe-area-bottom, --keyboard-height));
      // transition-timing-function: initial, initial, initial;
      // transition-delay: initial, initial, initial;
      // transition-property: height, max-height;
      // transition-duration: 350ms;
    }

    .intercom-messenger-frame {
      // 107deg matches first screen. 95deg matches conversation screen. Split the difference.
      background-image: linear-gradient(100deg, rgb(106, 87, 135) 0%, rgb(57, 47, 73) 100%);
      height: calc(100%);
    }
  }
}

:root {

  ion-footer {
    ion-toolbar {
      &:last-of-type {
        padding-bottom: var(--ion-safe-area-bottom, 0);
      }
    }
  }

  .hardware-keyboard-enable {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    transform: translateX(-8000px);
    z-index: 999999999999;

    &--button {
      z-index: 99999999999;
    }
  }

  --td-safe-area-bottom: var(--ion-safe-area-bottom);

  /* Keyboard safe area fixes for moving the 
  bottom footer when keyboard opens on iOS */
  &.plt-ios.plt-mobile.plt-capacitor,
  &.plt-ios.plt-mobile.plt-mobileweb.plt-pwa {

    // &.searchbar--has-focus.keyboard--visible {
    &.keyboard--visible {
      // 8 - 375 x ---px
      // 8+ - 414 x 716px
      // 11 - 414 x 852px
      // 11Pro - 375 x 768px

      --ion-safe-area-bottom: var(--keyboard-height);

      .hardware-keyboard-enable {
        bottom: 80px;
        transform: translateX(0px) translateY(0px);
      }

      /* iPhones */
      // &.plt-iphone {
      //   @media (orientation: landscape) {
      //     @media only screen and (max-width: 699.9px) {
      //       // iPhone SE
      //       --keyboard-height: 205px;
      //     }

      //     @media only screen and (min-width: 700px) and (max-width: 799.9px) {
      //       @media only screen and (max-height: 399.9px) {
      //         // iPhone 8
      //         // iPhone 11pro
      //         --keyboard-height: 210px;
      //       }

      //       @media only screen and (min-height: 400px) {
      //         // iPhone 8+
      //         --keyboard-height: 205px;
      //       }
      //     }

      //     @media only screen and (min-width: 800px) {
      //       // iPhone 11
      //       --keyboard-height: 215px;
      //     }
      //   }

      //   @media (orientation: portrait) {
      //     @media only screen and (max-height: 699.9px) {
      //       // iPhone SE
      //       --keyboard-height: 260px;
      //     }

      //     @media only screen and (min-height: 700px) and (max-height: 799.9px) {
      //       @media only screen and (max-width: 399.9px) {
      //         // iPhone 8
      //         // iPhone 11pro
      //         --keyboard-height: 335px;
      //       }

      //       @media only screen and (min-width: 400px) {
      //         // iPhone 8+
      //         --keyboard-height: 270px;
      //       }
      //     }

      //     @media only screen and (min-height: 800px) {
      //       // iPhone 11
      //       --keyboard-height: 345px;
      //     }
      //   }
      // }

      /* iPads */
      &.plt-ipad {
        --ion-safe-area-bottom: calc(var(--keyboard-height));
        //   // 9.7" - 741 x 1004px
        //   // 7thG - 810 x 1060px
        //   // Air3G - 810 x 1112px
        //   // 11" - 810 x 1170px
        //   // 12.9" - 1000 x 1342px

        //   @media (orientation: landscape) {
        //     @media only screen and (max-height: 749.9px) {
        //       // 9.7
        //       --keyboard-height: 410px;
        //     }

        //     @media only screen and (min-height: 750px) and (max-height: 990.9px) {
        //       @media only screen and (max-width: 1099.9px) {
        //         // 7thG
        //         --keyboard-height: 410px;
        //       }

        //       @media only screen and (min-width: 1100px) and (max-width: 1149.9px) {
        //         // Air3G
        //         --keyboard-height: 410px;
        //       }

        //       @media only screen and (min-width: 1150px) {
        //         // 11"
        //         --keyboard-height: 428px;
        //       }
        //     }

        //     @media only screen and (min-height: 991px) {
        //       --keyboard-height: 500px;
        //     }
        //   }

        //   @media (orientation: portrait) {
        //     @media only screen and (max-width: 768.9px) {
        //       // 9.7
        //       --keyboard-height: 320px;
        //     }

        //     @media only screen and (min-width: 769px) and (max-width: 990.9px) {
        //       @media only screen and (max-height: 1099.9px) {
        //         // 7thG
        //         --keyboard-height: 320px;
        //       }

        //       @media only screen and (min-height: 1100px) {
        //         // 11"
        //         --keyboard-height: 340px;
        //       }
        //     }

        //     @media only screen and (min-width: 991px) {
        //       // 12.9" - 1342px
        //       --keyboard-height: 405px;
        //     }
        //   }
      }
    }
  }

  .keyboard--disabled {
    --keyboard-height: var(--td-safe-area-bottom);

    .hardware-keyboard-enable {
      display: none;
    }
  }

}
