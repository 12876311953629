$animation-duration: 0.5s;

.tour-backdrop {
  will-change: opacity;
  transition: opacity 200ms ease-in-out;
  transform: translateZ(0.1px);
}

.tour-backdrop-container {
  animation: fade-in-1 $animation-duration forwards 1 ease-in-out;
}

@keyframes fade-in-1 {
  0% {
    opacity: .01;
  }

  100% {
    opacity: 1;
  }
}
