@use 'sass:math';

$color-names: primary secondary tertiary success warning action danger dark medium light button transparent;
$color-styles: shade tint contrast;
$sides: top bottom left right;

.align-center {
  text-align: center !important;
}

.align-justify {
  text-align: justify !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-top {
  vertical-align: top !important;
}

.animate-none {
  animation: none !important;

  * {
    animation: none !important;
  }
}

// BORDERS

.border {
  border: solid 1px rgba(0, 0, 0, 0.5);

  &-shadow {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  }

  &-shadow-inset {
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  }

  &-bottom-line {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      display: block;
      height: 1px;
      width: 70%;
      opacity: 0.6;
      margin-bottom: -1px;
      background-image: linear-gradient(to var(--bottom-line-direction), rgba(0, 0, 0, 0), var(--ion-color-primary-shade));
    }

    &--left {
      &::after {
        left: 0px;
        --bottom-line-direction: left;
      }
    }

    &--right {
      &::after {
        --bottom-line-direction: right;
        right: 0px;
      }
    }
  }
}

@for $i from 0 through 5 {
  .border-#{$i} {
    border-width: #{$i}px !important;
  }

  @each $side in $sides {
    .border-#{$side}-#{$i} {
      border-#{$side}: solid #{$i}px !important;
    }
  }
}

@for $i from 0 through 15 {
  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}

.background-overlay {
  background-color: var(--td-overlay-background, rgba(0, 0, 0, 0.3));
}

.background-none {
  background: none !important;
}

@each $color in $color-names {

  .background-#{""+$color} {
    background-color: var(--ion-color-#{$color});
  }

  .border-#{""+$color} {
    border-color: var(--ion-color-#{$color}) !important;

    @if $color !='transparent' {
      &.border-shadow {
        box-shadow: 0px 0px 5px 0px rgba(var(--ion-color-#{$color}-rgb), 0.55);
      }

      &.border-shadow-inset {
        box-shadow: inset 0px 0px 5px 0px rgba(var(--ion-color-#{$color}-rgb), 0.55);
      }
    }
  }

  .text-#{""+$color} {
    color: var(--ion-color-#{$color});
  }

  @if $color !='transparent' {
    @each $style in $color-styles {

      .background-#{""+$color}-#{$style} {
        background-color: var(--ion-color-#{$color}-#{$style});
      }

      .border-#{""+$color}-#{$style} {
        border-color: var(--ion-color-#{$color}-#{$style});

        &.border-shadow {
          box-shadow: 0px 0px 5px 0px rgba(var(--ion-color-#{$color}-rgb), 0.55);
        }

        &.border-shadow-inset {
          box-shadow: inset 0px 0px 5px 0px rgba(var(--ion-color-#{$color}-rgb), 0.55);
        }
      }

      .text-#{""+$color}-#{$style} {
        color: var(--ion-color-#{$color}-#{$style});
      }
    }
  }
}

.columns {
  @for $i from 0 through 5 {
    &.column-count-#{$i} {
      column-count: #{$i};
      -webkit-column-count: #{$i};
      column-width: #{math.div(100%, $i)};
    }
  }

  .nobreak {
    break-inside: avoid;
  }
}

.columns>* {
  display: inline-block;
  width: 100%;
}


$pointer-styles: pointer grab move;

@each $style in $pointer-styles {
  .cursor-#{$style} {
    cursor: #{$style};
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// faded-x
.faded {
  opacity: 0.5;
}

@for $i from 0 through 10 {
  .faded-#{$i} {
    opacity: #{math.div($i,10)};
  }
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-align-end {
  align-self: flex-end !important;
}

.flex-align-start {
  align-self: flex-start !important;
}

.flex-content-center {
  align-content: center !important;
}

.flex-content-end {
  align-content: flex-end !important;
}

.flex-content-start {
  align-content: flex-start !important;
}

.flex-items-center {
  align-items: center !important;
}

.flex-items-end {
  align-items: flex-end !important;
}

.flex-items-start {
  align-items: flex-start !important;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-fixed {
  flex-grow: 0;
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}


.flip-vertical {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.flip-horizontal {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.hidden {
  display: none;
}

.hover-effect-opacity {
  @media (any-hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.hover-effect {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    opacity: 0;
    transition: opacity 200ms;
    background: var(--td-button-color-hover, #6A5787);
    z-index: -1;
  }

  &:hover {
    &::before {
      opacity: var(--td-hover-effect-opacity, 0.1) !important;
    }
  }
}

.hover-effect-click {
  position: relative;

  &:not(.ios) {
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;

      opacity: 0;
      will-change: opacity;
      transform: translate3d(0, 0, 0);
      transition: opacity 100ms;
      background: var(--td-button-color-hover, #6A5787);
      z-index: -1;
    }

    &:active {
      &::before {
        opacity: 0.6 !important;
      }
    }
  }

  &.ios {
    transition: opacity 50ms;
    transition-delay: 100ms;

    &:active {
      opacity: 0.2 !important;
      transition-delay: 50ms;
    }
  }
}


.hover-effect-filter {

  will-change: filter;
  transition: filter 200ms ease-in-out;
  transform: translate3d(0.1px, 0, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0.1px, 0, 0px);
  -webkit-transform-style: preserve-3d;

  &:hover {
    filter: drop-shadow(0 0 2px var(--ion-color-light));
  }

  &.subtle {
    transition-duration: 50ms;

    &:hover {
      filter: drop-shadow(0 0 1px var(--ion-color-light));
    }
  }
}

.hover-effect-filter-saturate {

  will-change: filter;
  transition: filter 200ms ease-in-out;
  transform: translate3d(0.1px, 0, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0.1px, 0, 0px);
  -webkit-transform-style: preserve-3d;
  filter: saturate(50%);

  &:hover {
    filter: saturate(100%);
  }
}

.hover-effect-pop {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  &:hover {
    animation: pop 200ms ease-in 1 forwards normal;
  }
}

.hover-effect-table {
  &:hover {
    background: rgba(var(--td-button-color-hover-rgb, 106,
          87,
          135), 0.1);
  }
}

// margin-right-x, padding-right-x
$list: padding margin font-size line-height;
$edges: top bottom left right;
$modes: absolute relative fixed sticky static;

@each $mode in $modes {
  .position-#{$mode} {
    position: #{$mode};
  }
}

@for $i from 0 through 5 {
  @each $edge in $edges {
    .#{$edge}-#{$i} {
      #{$edge}: #{$i}px !important;
    }
  }
}

@for $i from 0 through 50 {
  @each $prop in $list {
    .#{$prop}-#{$i} {
      #{$prop}: #{$i}px !important;
    }

    @if $i>5 {
      .#{$prop}-#{$i}0 {
        #{$prop}: #{$i}0px !important;
      }
    }
  }
}

@each $prop in $list {
  @each $edge in $edges {
    .#{$prop}-#{$edge}-auto {
      #{$prop}-#{$edge}: auto !important;
    }

    @for $i from 0 through 50 {
      .#{$prop}-#{$edge}-#{$i} {
        #{$prop}-#{$edge}: #{$i}px !important;
      }

      @if $i>5 {
        .#{$prop}-#{$edge}-#{$i}0 {
          #{$prop}-#{$edge}: #{$i * 10}px !important;
        }
      }
    }
  }
}

// end margin-right-x, padding-right-x

.gpu-layer {
  will-change: transform;
  transform: translateZ(0.1);
  z-index: 0;
}

.gpu-layer-ios {
  // Safari fixes
  transform: translate3d(0, 0, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0px);
  -webkit-transform-style: preserve-3d;
}

.no-print {
  @media print {
    visibility: hidden;
  }
}

.no-wrap {
  white-space: nowrap;
}

.no-ripple {
  --background-activated-opacity: 0;
  --ripple-color: transparent;
}

.wrap {
  white-space: pre-wrap;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.scroll-container {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &--invisible {
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:window-inactive {
      visibility: hidden;
      display: none;
      width: 0px;
    }
  }
}

.select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

@each $edge in $edges {
  .sticky-#{$edge} {
    position: sticky;
    #{$edge}: 0;
    z-index: 3;
  }
}


.height-auto {
  height: fit-content !important;
}

.width-auto {
  width: auto !important;
}

@for $i from 0 through 100 {
  .height-#{$i} {
    height: round(percentage(math.div($i, 100))) !important;
  }

  .height-#{$i}-px {
    height: #{$i}px !important;
  }

  .width-#{$i} {
    width: round(percentage(math.div($i, 100))) !important;
  }

  .width-#{$i}-px {
    width: #{$i}px !important;
  }

  .min-height-#{$i}-px {
    min-height: #{$i}px !important;
  }

  .max-height-#{$i}-px {
    max-height: #{$i}px !important;
  }

  .min-width-#{$i}-px {
    min-width: #{$i}px !important;
  }

  .max-width-#{$i}-px {
    max-width: #{$i}px !important;

    >* {
      max-width: inherit;
    }
  }
}

.text-font-default {
  font-family: var(--ion-font-family, 'Barlow');
}

.fine-print {
  font-size: 11px;
  font-style: italic;
}

.subtext {
  font-size: 13px;
  opacity: 0.8;
}

.text-normal {
  font-weight: normal;
  text-transform: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-none {
  text-transform: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.safe-area-top {
  padding-top: var(--ion-safe-area-top, 0) !important;
}

.safe-area-bottom {
  padding-bottom: var(--ion-safe-area-bottom, 0) !important;
}
