:root.theme-embedded {
  --ion-background-color: rgba(0, 0, 0, 0);

  * {
    background-color: var(--ion-background-color);
    --color: var(--ion-color-medium);
    --background: var(--ion-background-color);
    user-select: none;
  }

  // Mouse click-through styles
  &.transparency-enabled {
    pointer-events: none;

    .click-through, .click-through * {
      pointer-events: none;
    }

    .click-on, .click-on * {
      pointer-events: all;
    }
  }
}
