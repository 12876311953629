// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {

  // Safe area variables - Retain values even if Ionic vars are overridden.
  --safe-area-top: var(--safe-area-inset-top, env(safe-area-inset-top));
  --safe-area-bottom: var(--safe-area-inset-bottom, env(safe-area-inset-bottom));
  --safe-area-left: var(--safe-area-inset-left, env(safe-area-inset-left));
  --safe-area-right: var(--safe-area-inset-right, env(safe-area-inset-right));

  --ion-safe-area-top: var(--safe-area-top, 0px);
  --ion-safe-area-bottom: var(--safe-area-bottom, 0px);
  --ion-safe-area-left: var(--safe-area-left, 0px);
  --ion-safe-area-right: var(--safe-area-right, 0px);

  --logo-img: url('../assets/img/logo-white-256.png');

  --ion-color-primary: #915fc2;
  --ion-color-primary-rgb: 145, 95, 194;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #8054ab;
  --ion-color-primary-tint: #9c6fc8;

  --ion-color-secondary: #6266FD;
  --ion-color-secondary-rgb: 98, 102, 253;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #565adf;
  --ion-color-secondary-tint: #7275fd;

  --ion-color-tertiary: #6785d4;
  --ion-color-tertiary-rgb: 103, 133, 212;
  --ion-color-tertiary-contrast: #FFFFFF;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5b75bb;
  --ion-color-tertiary-tint: #7691d8;

  --ion-color-success: #33a603;
  --ion-color-success-rgb: 51, 166, 3;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2d9203;
  --ion-color-success-tint: #47af1c;

  --ion-color-warning: #E8C547;
  --ion-color-warning-rgb: 232, 197, 71;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ccad3e;
  --ion-color-warning-tint: #eacb59;

  --ion-color-danger: #A53033;
  --ion-color-danger-rgb: 165, 48, 51;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #912a2d;
  --ion-color-danger-tint: #ae4547;

  // --ion-color-danger: #a4305e;
  // --ion-color-danger-rgb: 164, 48, 94;
  // --ion-color-danger-contrast: #ffffff;
  // --ion-color-danger-contrast-rgb: 255, 255, 255;
  // --ion-color-danger-shade: #902a53;
  // --ion-color-danger-tint: #ad456e;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
}



/** Extra Colors For Elements */

:root {
  --ion-color-button: #544A63;
  --ion-color-button-rgb: 84, 74, 99;
  --ion-color-button-contrast: #ffffff;
  --ion-color-button-contrast-rgb: 255, 255, 255;
  --ion-color-button-shade: #A577CD;
  --ion-color-button-tint: #6A5787;
}

.ion-color-button {
  --ion-color-base: var(--ion-color-button);
  --ion-color-base-rgb: var(--ion-color-button-rgb);
  --ion-color-contrast: var(--ion-color-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-shade);
  --ion-color-tint: var(--ion-color-button-tint);
}

:root {
  --ion-color-button-cta: #6785D4;
  --ion-color-button-cta-rgb: 103, 133, 212;
  --ion-color-button-cta-contrast: #000000;
  --ion-color-button-cta-contrast-rgb: 0, 0, 0;
  --ion-color-button-cta-shade: #5b75bb;
  --ion-color-button-cta-tint: #7691d8;
}

.ion-color-button-cta {
  --ion-color-base: var(--ion-color-button-cta);
  --ion-color-base-rgb: var(--ion-color-button-cta-rgb);
  --ion-color-contrast: var(--ion-color-button-cta-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-cta-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-cta-shade);
  --ion-color-tint: var(--ion-color-button-cta-tint);
}

:root {
  --ion-color-button-cta-secondary: #A577CD;
  --ion-color-button-cta-secondary-rgb: 165, 119, 205;
  --ion-color-button-cta-secondary-contrast: #000000;
  --ion-color-button-cta-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-button-cta-secondary-shade: #9169b4;
  --ion-color-button-cta-secondary-tint: #ae85d2;
}

.ion-color-button-cta-secondary {
  --ion-color-base: var(--ion-color-button-cta-secondary);
  --ion-color-base-rgb: var(--ion-color-button-cta-secondary-rgb);
  --ion-color-contrast: var(--ion-color-button-cta-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-cta-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-cta-secondary-shade);
  --ion-color-tint: var(--ion-color-button-cta-secondary-tint);
}


@import './variables-theme-night.scss';
@import './variables-theme-embedded.scss';
@import './variables-theme-electron.scss';
