@font-face {
  font-display: swap;
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: local('Barlow Italic'), local('Barlow-Italic'), url("/assets/fonts/barlow/7cHrv4kjgoGqM7E_Cfs7wHo.ttf") format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'), url("/assets/fonts/barlow/7cHsv4kjgoGqM7E_CfOA5WouvT8.ttf") format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Regular'), local('Barlow-Regular'), url("/assets/fonts/barlow/7cHpv4kjgoGqM7E_DMs8.ttf") format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Bold'), local('Barlow-Bold'), url("/assets/fonts/barlow/7cHqv4kjgoGqM7E3t-4s51op.ttf") format('truetype');
}
