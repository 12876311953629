:root {
  $drag-start-animation-time: 250ms;
  $drag-end-animation-time: 250ms;

  /** CDK Drag Drop Styles */
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }


  .cdk-drag-preview {

    // background-color: rgba(0, 255, 0, 0.3);

    .cdk-drag-preview-container {
      position: absolute;
      // background-color: rgba(255, 0, 0, 0.3);
      box-sizing: border-box;
      opacity: .8;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fade-in $drag-start-animation-time forwards 1 ease-in-out;

      height: max(8vmax - 15px, 100px);
      width: calc(max(8vmax - 15px, 100px)*0.70874);

      >* {
        position: relative;

        right: calc(calc(max(8vmax - 15px, 100px)*0.70874)/2);
        bottom: calc(max(8vmax - 15px, 100px)/2);

        --card-block-height: max(8vmax - 15px, 100px);
        --card-block-width: calc(max(8vmax - 15px, 100px)*0.70874);

        animation: vibrate-1 1500ms forwards infinite ease-in-out;
      }
    }

    &.cdk-drag-animating {
      transition: transform $drag-end-animation-time cubic-bezier(0, 0, 0.2, 1);

      td-card-block {
        // transform: rotateX(0deg) scale(1);
        animation: fade-out $drag-end-animation-time forwards 1 ease-in-out;
      }
    }
  }
}
